import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Jumbotron from '../components/Jumbotron/Jumbotron';
import Layout from '../layout';

import styles from './pricing.module.scss';
import config from '../../data/SiteConfig';

class PricingPage extends Component {
  render() {
    return (
      <Layout>
        <div className={styles.pricingContainer}>
          <Helmet title={`Tarification | ${config.siteTitle}`} />
          <Jumbotron headline1="Tarification" subtitle="une solution à la portée de votre équipe" />
          <center>
            <table className={styles.pricingTable}>
              <colgroup>
                <col />
                <col className={styles.colFree} />
                <col className={styles.colProject} />
                <col className={styles.colEnterprise} />
              </colgroup>
              <thead className={styles.header}>
                <th scope="col" />
                <th scope="col">Gratuit</th>
                <th scope="col">Projet</th>
                <th scope="col">Entreprise</th>
              </thead>
              <tr className={styles.priceRow}>
                <th scope="row" />
                <td>
                  <span className={styles.price}>0€</span>
                  <span className={styles.billingPeriod}>utilisateur / mois</span>
                </td>
                <td>
                  <span className={styles.price}>9€</span>
                  <span className={styles.billingPeriod}>utilisateur / mois</span>
                </td>
                <td>
                  <span className={styles.price}>14€</span>
                  <span className={styles.billingPeriod}>utilisateur / mois</span>
                </td>
              </tr>
              <tr className={styles.ctaRow}>
                <td />
                <td><a href="https://beta.spreadsheep.io">Commencer maintenant</a></td>
                <td><a href="https://beta.spreadsheep.io">Commencer maintenant</a></td>
                <td><a href="https://beta.spreadsheep.io">Commencer maintenant</a></td>
              </tr>
              <tr className={styles.sectionTitle}>
                <td>Fonctionnalités</td>
                <td />
                <td />
                <td />
              </tr>
              <tr className={styles.rowDetail}>
                <th scope="row">Enregistrements par projet</th>
                <td>500</td>
                <td>5 000</td>
                <td>50 000</td>
              </tr>
              <tr className={styles.rowDetail}>
                <th scope="row">Stockage de fichiers</th>
                <td>10 Mo</td>
                <td>1 Go</td>
                <td>10 Go</td>
              </tr>
              <tr className={styles.rowDetail}>
                <th scope="row">Permissions</th>
                <td>-</td>
                <td>Lecteurs / Collaborateurs</td>
                <td>Lecteurs / Collaborateurs</td>
              </tr>
              <tr className={styles.rowDetail}>
                <th scope="row">Rapports</th>
                <td>Filigrane</td>
                <td>
                  <FontAwesomeIcon icon="check" />
                </td>
                <td>
                  <FontAwesomeIcon icon="check" />
                </td>
              </tr>
              <tr className={styles.sectionTitle}>
                <td>Historique et Sauvegardes</td>
                <td />
                <td />
                <td />
              </tr>
              <tr className={styles.rowDetail}>
                <th scope="row">Historique des enregistrements</th>
                <td>-</td>
                <td>1 semaine</td>
                <td>1 an</td>
              </tr>
              <tr className={styles.rowDetail}>
                <th scope="row">Sauvegardes</th>
                <td>-</td>
                <td>manuelles</td>
                <td>manuelles et automatiques (quotidienne, hebdomadaire et mensuelle)</td>
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td />
              </tr>
            </table>
          </center>
        </div>
      </Layout>
    );
  }
}

export default PricingPage;
